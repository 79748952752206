import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const RiskManagementandComplianceAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                
                我哋嘅風險管理同合規服務旨在幫助企業識別同解決潛在風險，同時確保遵守監管要求。我哋會進行徹底嘅風險評估，嚟找出漏洞，同埋制定緩解漏洞嘅策略。我哋嘅團隊會提供有關實施合規措施同埋監察法規變動嘅指引。
                我哋幫你建立一個風險管理框架，支援業務連續性同埋將潛在嘅中斷減至最低。我哋專注於主動風險管理同監管合規，幫你保障你嘅業務同維持營運穩定。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                客戶可以透過我哋嘅服務獲得強大嘅風險管理框架同埋加強合規做法。我哋嘅方法可以幫你識別同解決潛在風險，確保你嘅企業免受預期唔到嘅挑戰。你會受益於更好嘅遵守監管要求同埋更堅實嘅業務連續性基礎。
                我哋嘅專業知識支援主動嘅風險管理，減少中斷嘅可能性，同埋確保你嘅營運保持符合法律標準。喺我哋嘅指導下，你可以有效噉導航監管環境同埋管理風險。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default RiskManagementandComplianceAbout;
