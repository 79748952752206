import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import PrivacyPolicyBanner from "./PrivacyPolicyBanner";
import PrivacyPolicyPost from "./PrivacyPolicyPost";

import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";


const PrivacyPolicy = () => {
  return (
    <>
    <Helmet>
        <title>{meta.privacypolicy.title}</title>
        <meta name="description" content={meta.privacypolicy.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <PrivacyPolicyBanner />
      <PrivacyPolicyPost />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
