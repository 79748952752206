// src/LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import meta from "../../../data/meta.json";
import { Helmet } from "react-helmet";
import { useAuth } from '../../../AuthContext';

const LoginForm = () => {


  const [useremail, setUseremail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State to store error messages
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (useremail === 'admin@gmail.com' && password === 'admin@123') {
      login(); // Set authentication state to true
      navigate('/admin'); // Redirect to admin page
    } else {
      setError('Invalid email or password.');
    }
  };

  return (

    <>

      <Helmet>
        <title>{meta.login.title}</title>
        <meta name="description" content={meta.login.desc} />
      </Helmet>


      <div className="container">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <div className="col-lg-4">
            <form onSubmit={handleSubmit} className="border p-4 rounded shadow">
              <h3 className="text-center mb-4">Login</h3>
              {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Email</label>
                <input
                  id="username"
                  type="text"
                  className="form-control"
                  value={useremail}
                  onChange={(e) => setUseremail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">Login</button>
            </form>
          </div>
        </div>
      </div></>

  );
};

export default LoginForm;
