import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const BudgetaryPlanningandAnalysisAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅預算計劃同分析服務提供全面嘅支援，幫你管理同優化預算資源。我哋首先會同你合作，制定一個符合你嘅業務目標嘅詳細預算計劃。包括建立預算、預測未來表現同埋分析歷史數據。
                我哋嘅團隊會提供預算趨勢嘅見解，識別潛在風險，同埋提供改善資本實力嘅建議。我哋會幫你根據目標監察預算表現，同埋根據數據驅動嘅分析作出明智嘅決定。我哋嘅目標係提供一個清晰嘅預算路線圖，支援你嘅戰略目標同埋提升你嘅資本穩定性。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                客戶可以受益於有結構嘅預算計劃，呢個計劃可以指導決策同埋支援長期穩定。我哋嘅服務可以提供有關預算績效嘅寶貴見解，幫你有效管理資源同埋達成你嘅預算目標。
你會對你嘅預算狀況同潛在風險有更清楚嘅認識，令你可以作出明智嘅決定同策略調整。透過利用我哋嘅專業知識，你可以改善預算管理，增強預算流程，同埋確保你嘅預算策略同你嘅整體業務目標一致。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default BudgetaryPlanningandAnalysisAbout;
