import React from "react";
import Footer from "../common/Footer/Footer";
import TopAbout from "./TopAbout";
import Menu from "../common/Menu/Menu";
import LastedBlog from "./LastedBlog";
import GalleryHome from "./GalleryHome";
import CategoryHome from "./CategoryHome";
import ContactHome from "./ContactHome";
import Promotion from "./Promotion";
import SliderHome from "./SliderHome";
import Services from "./Services";
import { Helmet } from "react-helmet";
import meta from "../../data/meta.json";
import TopBanner from "../Pages/TopBanner/topBanner"


const Home = () => {


  return (
    <>
      <Helmet>
        <title>{meta.home.title}</title>
        <meta name="description" content={meta.home.desc} />
      </Helmet>
      <TopBanner/>
      <Menu header_style_2={true} />
      <SliderHome />
      <TopAbout />
      <CategoryHome />
      <Services />
      <GalleryHome />
      <Promotion />
      <ContactHome />
      <LastedBlog />
      <Footer />
    </>
  );
};

export default Home;
