import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-1 pb-30">
                      <div className="footer-logo mb-30">
                        <Link to="/"><img src="/assets/img/logo/logo.png" alt="" /></Link>
                      </div>
                      <p>喺 Rootemi，我哋提供策略顧問服務，旨在透過量身定制嘅解決方案同專家指導，幫助企業達成目標。 </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-2 pb-30">
                    

                      <h3 className="tp-footer__widget-title text-white">快速链接</h3>
                      <ul>
                        <li><Link to="/about">关于我们
                        </Link></li>
                        <li><Link to="/service">服务</Link></li>
                        <li><Link to="/faq">常问问题 </Link></li>
                        <li><Link to="/portfolio">文件夹 </Link></li>
                        <li><Link to="/blog">博客  </Link></li>
                        <li><Link to="/contact">联系我们 </Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-3 pb-30">
                       <h3 className="tp-footer__widget-title text-white">地址</h3>
                      <ul>
                       
                        <li><span>香港尖沙咀廣東道 30 號銀繩</span></li>
                        <li><a href="tel:+85237038686">+85237038686</a></li>
                        <li><a href="mailto:consult@rootemi.hk">consult@rootemi.hk</a></li>
                        <li><span> 辦公時間：上午 9 時至下午 4 時</span></li>
                        <li><span></span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                    <h3 className="tp-footer__widget-title text-white">
我们的政策</h3>
                      <ul>
                      <li><Link to="/cookies-policy">Cookie 政策</Link></li>
                      <li><Link to="/privacy-policy">隐私政策</Link></li>
                      <li><Link to="/terms-and-conditions">条款及条件</Link></li>
                    
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="tp-copyrigh-text mb-30">
                <span>© {new Date().getFullYear()} © Rootemi 保留所有權利</span>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="tp-footer-social-icon mb-30 text-md-end">
                <ul>
                  {/* <li><a href="#"><i className="fab fa-facebook"></i></a></li> */}
                  <li><a href="https://x.com/RootemiConsult" target="_blank"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="https://it.pinterest.com/RootemiConsult/" target="_blank"><i className="fab fa-pinterest"></i></a></li>
                  {/* <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i className="fab fa-youtube"></i></a></li> */}
                  <li><a href="https://www.linkedin.com/in/rootemi-business-consultancy-31054332a/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default Footer;
