import React from "react";
import { Link } from "react-router-dom";
// import BlogSidebar from "../Blog/BlogSidebar";

const CookiesPolicyPost = () => {
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-20">
                <article className="postbox__item format-image mb-50 transition-3">
                  
                  <div className="postbox__content">


                    <div className="postbox__text">
                     


                
                      <p>喺 rootemi，我哋相信當你瀏覽我哋嘅網站嗰陣，我哋會透明噉收集同使用我哋嘅資料。呢個政策會講解咩係 Cookie 、我哋點樣用佢哋，同埋你點樣管理你嘅偏好設定</p>
                    
                    


                      <h3 className="postbox__subtite mb-25">
                      曲奇係乜嘢？
                      </h3>
                      <p>Cookie 係當你瀏覽網站嗰陣儲存喺你裝置上面嘅小型檔案。呢啲檔案係無害嘅，但係喺幫我哋提升你喺我哋網站嘅體驗方面發揮重要作用。佢哋幫我哋了解訪客點樣同我哋提供嘅內容互動，同埋確保我哋嘅網站運作順利。</p>
                     


                      <h3 className="postbox__subtite mb-25">
                      點解我哋要用曲奇？
                      </h3>
                      <p>我哋用 Cookie 嘅原因有幾個：</p>
                      <p>效能同功能：有啲 Cookie 係網站正常運作嘅必要條件。佢哋令到個網站載入得更快同埋運作得有效率，確保你可以輕鬆導覽。</p>
                      <p> 分析：呢啲 Cookie 可以畀我哋深入瞭解訪客點樣使用我哋嘅網站。透過理解呢一點，我哋可以改善內容同設計，令你嘅參觀更加愉快。</p>
                      <p>自訂：我哋亦會用 Cookie 嚟記住你嘅偏好設定，噉你就唔使每次去都調整設定。噉樣你就可以更方便噉探索我哋提供嘅服務。</p>


                      <h3 className="postbox__subtite mb-25">
                      管理緊你嘅 Cookie 偏好設定
                      </h3>
                      <p>當你第一次瀏覽我哋嘅網站嗰陣，你可以選擇接受或者拒絕 Cookie 。幾乎所有網絡瀏覽器都設定咗自動接受 Cookie ，但係你可以修改你嘅瀏覽器設定嚟管理呢個操作。如果你唔想收到 Cookie ，你可以透過你嘅瀏覽器設定封鎖佢哋。請注意，停用 Cookie 可能會影響我哋網站為你運作嘅方式。</p>
              

                      <h3 className="postbox__subtite mb-25">
                      第三方 Cookie
                      </h3>
                      <p>我哋亦可能會用嚟自信任嘅供應商嘅第三方 Cookie 。呢啲 Cookie 有助我哋更加了解你同我哋網站嘅互動，但係放心，我哋唔會收集任何個人資料。所有資料都會保持匿名，只係用嚟提升你嘅體驗。</p>
                     


                      <h3 className="postbox__subtite mb-25">
                      保持你嘅數據安全
                      </h3>
                      <p>我哋認真對待你嘅私隱。透過 Cookie 收集嘅資料係安全嘅，而且要負責任噉用。我哋遵守新加坡有關資料私隱嘅適用法律同法規，包括個人資料保護法（ PDPA ）。</p>
                      


                      <h3 className="postbox__subtite mb-25">
                      呢個政策嘅更新
                      </h3>
                      <p>呢個政策可能會不時更新，以反映我哋嘅做法或者適用法規嘅任何變動。我哋鼓勵你定期睇返佢，以便緊貼最新消息。</p>
                      <p>如果你對我哋使用 Cookie 有任何疑問或疑慮，隨時透過  consult@rootemi.hk 同我哋聯絡。</p>
                      
                
                    </div>

                  </div>
                </article>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicyPost;
