// import React, { useRef,useState } from "react";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import VideoPopup from "../../common/Modals/VideoPopup";
// import BlogSidebar from "./BlogSidebar";

// const settings = {
//   autoplay: true,
//   autoplaySpeed: 4000,
//   dots: false,
//   fade: false,
//   arrows: false,
// };

const BlogPost = () => {
  // const sliderRef = useRef(null);
  // const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-4 col-12">
              <div className="postbox__wrapper">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                  <Link  to="/enhancing-operational-efficiency-simple-steps-for-businesses">
                      <img src="/assets/img/blog/business Strategy .jpg" alt=""  className="w-100"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                  
                    <h3 className="postbox__title">
                    <Link  to="/enhancing-operational-efficiency-simple-steps-for-businesses">
                      提高營運效率：企業嘅簡單步驟
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      喺現今快速發展嘅商業環境下，提高營運效率係保持競爭力嘅必要條件。企業，特別係喺新加坡，面對獨特嘅挑戰，包括成本上升、不斷發展嘅技術
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/enhancing-operational-efficiency-simple-steps-for-businesses">
                        睇多啲
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="postbox__wrapper">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/expanding-into-new-markets-what-you-need-to-know">
                      <img src="/assets/img/blog/expanding business.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                   
                    <h3 className="postbox__title">
                      <Link to="/expanding-into-new-markets-what-you-need-to-know">
                      擴展新市場：你需要知道嘅嘢
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      對於旨在成長、多元化同挖掘新機會嘅企業嚟講，擴張到新市場係一個重要嘅舉動。呢個過程涉及計劃同策略執行。無論你係一間興起嘅初創公司定係一間尋求更廣闊視野嘅成熟企業，
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/expanding-into-new-markets-what-you-need-to-know">
                        睇多啲
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="postbox__wrapper">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/how-to-developa-winning-business-strategy">
                      <img src="/assets/img/blog/operational efficiency.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                  
                    <h3 className="postbox__title">
                      <Link to="/how-to-developa-winning-business-strategy">
                      點樣制定一個贏嘅商業策略
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      制定堅實嘅商業策略對於任何尋求成長同成功嘅組織都係必要嘅。佢有助將你公司嘅願景同可量度嘅目標一致，為決策同營運提供清晰嘅路徑。無論你係一間初創公司定係一間成熟嘅企業，有
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/how-to-developa-winning-business-strategy">
                        睇多啲
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
  );
};

export default BlogPost;
