import { Link } from "react-router-dom";

const ServiceAbout = () => {

  return (
    <>
      <div className="tp-about-section black-bg p-relative">
        <div
          className="tp-ab-bg d-none d-lg-block"
          //   data-background="/assets/img/service/service-bg.jpg"
          style={{
            backgroundImage: `url("/assets/img/service/Service page1.jpg")`,
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-6">
              <div className="tp-ab-text pt-130 pl-150 p-relative">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-30">
                  為成長量身訂造嘅解決方案
                  </span>
                  <h2 className="tp-section-title text-white">
                  幫你嘅生意達到新嘅高度
                  </h2>
                  <p className="mb-30">
                  喺 Rootemi，我哋提供實用嘅顧問服務，旨在滿足你嘅商業需要。我哋嘅團隊會提供有關策略、營運、市場進入等等嘅專家建議。我哋專注於提供可行嘅分析資料，以支援你嘅目標同埋推動可持續成功。
                  </p>
                 

                  <div className="mt-50 mb-130">
                    {/* <Link to="/service" className="tp-btn-white">
                      查看更多
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </Link> */}
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
     
    </>
  );
};

export default ServiceAbout;
