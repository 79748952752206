import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import LeadershipandTeamDevelopmentAbout from "./LeadershipandTeamDevelopmentAbout";
import LeadershipandTeamDevelopmentBanner from "./LeadershipandTeamDevelopmentBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const LeadershipandTeamDevelopment = () => {
  return (
    <>
    <Helmet>
        <title>{meta.leadershipandteamdevelopment.title}</title>
        <meta name="description" content={meta.leadershipandteamdevelopment.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <LeadershipandTeamDevelopmentBanner />
      <LeadershipandTeamDevelopmentAbout />
      
      <Contact />
      <Footer />
    </>
  );
};

export default LeadershipandTeamDevelopment;
