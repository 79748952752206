import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import DigitalTransformationConsultancyAbout from "./DigitalTransformationConsultancyAbout";
import DigitalTransformationConsultancyBanner from "./DigitalTransformationConsultancyBanner";

import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const DigitalTransformationConsultancy = () => {
  return (
    <>
     <Helmet>
        <title>{meta.digitaltransformationconsulting.title}</title>
        <meta name="description" content={meta.digitaltransformationconsulting.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <DigitalTransformationConsultancyBanner />
      <DigitalTransformationConsultancyAbout />
      
      <Contact />
      <Footer />
    </>
  );
};

export default DigitalTransformationConsultancy;
