import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li><Link to="/">家</Link></li>
      <li> <Link to="/about">关于</Link></li>
      <li> <Link to="/service">服务</Link></li>
      <li><Link to="/faq">常问问题 </Link></li>
      <li><Link to="/portfolio">文件夹</Link></li>
      <li><Link to="/blog">博客 </Link></li>
      <li> <Link to="/contact">接触</Link>
</li>
    </ul>
  );
};

export default Menus;
