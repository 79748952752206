import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData); // Debugging: Log form data

    emailjs.send("service_bs9k8k6", "template_n2andll", formData, "OuRieeM4Uc3ZVmNTL")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        // Reset form after successful submission
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <div className="tp-sv-contact grey-bg-4 pt-140 pb-140">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tp-section-wrapper text-center pb-40">
              <span className="tp-section-subtitle mb-25">保持聯絡</span>
              <h2 className="tp-section-title">唔知你搵緊啲咩？</h2>
            </div>
          </div>
        </div>
        <div className="row g-0  justify-content-center">
          <div className="col-lg-8">
            <div className="tp-contact-form tp-contact-form-2 white-bg pt-50 pl-50 pr-50 pb-55 mr-60">
              <h4 className="tp-contact-form-title">喺呢度聯絡我哋</h4>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="輸入你個名"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <br />
                <input
                  type="email"
                  name="email"
                  placeholder="輸入你嘅郵件"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <br />
                <textarea
                  name="message"
                  placeholder="輸入你嘅訊息"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <button type="submit" className="tp-btn-border">
                  送按摩
                  <span>
                    {/* SVG icons here */}
                  </span>
                </button>
              </form>
            </div>
          </div>
          {/* Rest of your component */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
