import React from "react";
import { Link } from "react-router-dom";

// portfolio items
const portfolio_data = [
  {
    id:1,
    img:'/assets/img/project/project1.jpg',
    // tag:"Business",
    title:"零售鏈優化"
  },
  {
    id:2,
    img:'/assets/img/project/project2.jpg',
    // tag:"Business",
    title:"科技初創公司擴張"
  },
  {
    id:3,
    img:'/assets/img/project/project3.jpg',
    // tag:"Business",
    title:"製造財務計劃"
  },
  {
    id:4,
    img:'/assets/img/project/project4.jpg',
    // tag:"Business",
    title:"物流數碼轉型"
  },
  {
    id:5,
    img:'/assets/img/project/project5.jpg',
    // tag:"Business",
    title:"醫療保健人力資源重組"
  },
  {
    id:6,
    img:'/assets/img/project/project6.jpg',
    // tag:"Business",
    title:"財務風險評估"
  },

]

const PortfolioItems = () => {
  return (
    <>
      <div className="project-page-list pt-140 pb-90">
        <div className="container">
          <div className="row">
            {portfolio_data.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-12">
              <div className="pj-list-item mb-50">
                <div className="pj-list__img">
                  <img src={item.img} alt="" />
                </div>
                <span>
                  <a href="#">{item.tag}</a>
                </span>
                <h4 className="pj-list__title">
                 {item.title}
                </h4>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioItems;
