import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BlogDetails4Banner from "./BlogDetails4Banner";
import BlogDetails4Post from "./BlogDetails4Post";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const HowtoDevelopaWinningBusinessStrategy = () => {
  return (
    <>
     <Helmet>
        <title>{meta.blog3.title}</title>
        <meta name="description" content={meta.blog3.desc} />
      </Helmet>
    
      <Menu header_style_2={true} />
      <BlogDetails4Banner />
      <BlogDetails4Post />
      <Footer />
    </>
  );
};

export default HowtoDevelopaWinningBusinessStrategy;
