import React from "react";
import { ServiceItem } from "../../Home/Services";
import { Link } from "react-router-dom";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">我哋嘅服務</span>
                <h2 className="tp-section-title">發掘顧問服務</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-xl-3 col-md-6">
              <Link to="/business-strategy-development">
                <ServiceItem
                  icon="pe-7s-arc"
                  title="商業策略發展"
                  desc="我哋會同你合作，制定清晰嘅策略，令佢哋符合你嘅業務目標同未來目標。"
                />
              </Link>
            </div>
            <div className="col-xl-3 col-md-6">
              <Link to="/operational-efficiency-consulting">  <ServiceItem
                icon="pe-7s-cloud-download"
                title="營運效率諮詢"
                desc="我哋會評估你而家嘅流程，幫你搵出提高效率同簡化營運嘅方法。"
              /></Link>
             
            </div>
            <div className="col-xl-3 col-md-6">
              <Link to="/marketentry-and-expansion-consulting">
              <ServiceItem
                icon="pe-7s-disk"
                title="市場進入同擴張顧問"
                desc="我哋會提供有關點樣有效進入新市場或者喺你而家嘅市場擴張嘅指引。"
              />
              
              </Link>
             
            </div>
            <div className="col-xl-3 col-md-6">
              <Link to="/budgetary-planning-and-analysis">
              <ServiceItem
                icon="pe-7s-hammer"
                title="預算計劃同分析"
                desc="我哋嘅專家會為你嘅業務增長提供預算預測、預算同埋計劃嘅見解。"
              />
              </Link>
            </div>
        
          <div className="col-xl-3 col-md-6">
            <Link to="/human-resources-consulting">
          <ServiceItem
              icon="pe-7s-umbrella"
              title="人力資源諮詢"
              desc="我哋會協助你嘅企業制定員工參與策略、組織結構同埋招聘流程。"
            />
            </Link>
          </div>
          <div className="col-xl-3 col-md-6">
          <Link to="/digital-transformation-consultancy">
          <ServiceItem
              icon="pe-7s-graph3"
              title="數碼轉型諮詢"
              desc="我哋幫助企業整合數碼工具同策略，嚟保持競爭力同埋改善整體營運。"
            />
            </Link>
          </div>
          <div className="col-xl-3 col-md-6">
          <Link to="/risk-managementand-compliance">
          <ServiceItem
              icon="pe-7s-cup"
              title="風險管理同合規"
              desc="我哋會幫手評估潛在風險，同埋提供解決方案，確保你嘅商家遵守當地法規。"
            />
            </Link>
          </div>
          <div className="col-xl-3 col-md-6">
          <Link to="/leadershipand-team-development">
          <ServiceItem
              icon="pe-7s-config"
              title="領導同團隊發展"
              desc="我哋提供有關提升領導技能同團隊動態嘅指引，以提高職場生產力。"
            />
            </Link>
          </div>
          
           
          
          
          
           
            
          
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
