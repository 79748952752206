import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const LeadershipandTeamDevelopmentAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅領導同團隊發展服務主要係提升你嘅領導團隊同員工嘅技能同效能。我哋提供定制嘅培訓計劃同工作坊，旨在發展領導能力、改善團隊動態同埋培養協作。
                我哋嘅團隊會同你合作，確定發展需求，同埋制定量身定制嘅計劃，以解決特定嘅挑戰同目標。透過喺領導同團隊發展方面提供資金，我哋會幫你建立一個更強大、更有凝聚力嘅團隊，推動組織成功同埋支持業務目標。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                透過我哋嘅發展計劃，客戶可以受益於提升領導技能同埋提升團隊績效。我哋嘅服務可以幫你建立有效嘅領導者，同埋培養一個協作嘅工作環境。你將會獲得實用嘅工具同見解，嚟解決團隊挑戰、改善溝通，同埋推動更好嘅成果。
                透過提供領導同團隊發展嘅資金，你可以創造出一個更加參與同有生產力嘅勞動力，為整體商業成功作出貢獻。我哋嘅專業知識確保你嘅團隊有足夠嘅裝備去達成組織目標同埋有效噉應對挑戰。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default LeadershipandTeamDevelopmentAbout;
