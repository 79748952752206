import React from "react";
import { Link } from "react-router-dom";
// import BlogSidebar from "../Blog/BlogSidebar";

const BlogDetailsPost = () => {
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-20">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb m-img">
                    <Link to="/blog-details">
                      <img src="/assets/img/blog/single-blog.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <Link to="/blog-details">
                          <i className="fal fa-user"></i> Tushar
                        </Link>
                      </span>
                      <span>
                        <i className="fal fa-calendar-day"></i> July 21, 2020{" "}
                      </span>
                      <span>
                        <Link to="/blog-details">
                          <i className="fal fa-comment"></i> 02 Comments
                        </Link>
                      </span>
                    </div>
                    <h3 className="postbox__title mb-25">
                      How To Develop Your Business Technology Roadmap
                    </h3>
                    <div className="postbox__text">
                      <p>
                        Quick summary ↬ Software development without a roadmap
                        is akin to driving off a cliff — an undertaking that
                        seriously jeopardizes your product’s life. Here’s how to
                        develop a business technology roadmap that ensures your
                        project safely reaches its final destination.
                      </p>
                      <p>
                        When people have an idea for a piece of software or an
                        app, they tend to be pretty energized about getting it
                        to market as quickly as possible. It’s exciting to
                        create an app or piece of software no one’s ever
                        imagined or built before. As software developers, we’re
                        usually right there with them.
                      </p>
                      <p>
                        At some point, though, we need to sit down with clients
                        and give them a sometimes sobering reality: software
                        development without a business technology roadmap can be
                        a lot like driving aimlessly from point A to point Z.
                        Sure, you get to discover new worlds and experience
                        unexpected adventures, but you also frequently get lost,
                        spend more money, and can lose enthusiasm for the
                        journey.
                      </p>

                      <h3 className="postbox__subtite mb-25">
                        What Is A Business Technology Roadmap? #
                      </h3>
                      <p>
                        Unlike detailed blueprints that lay out all tasks,
                        deadlines, bug reports, and more along the way,
                        technology roadmaps are high-level visual summaries
                        highlighting a company’s vision or plans.
                        <br />
                        <br />
                        In an Agile approach, a technology roadmap feeds the
                        sprint and grooming processes, providing insight into
                        how the product will travel from start to finish. It
                        makes it easier for development teams to:
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <p>
                            <img
                              src="/assets/img/blog/single-blog-2.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <p>
                            <img
                              src="/assets/img/blog/single-blog-3.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                      <h3 className="postbox__subtite mb-25">
                        How to become a top conference speaker?
                      </h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elitm sed do eiusmod tempor incididunt labore ets magna
                        aliquatenim minim veniam quis nostrud exercitation
                        ullamco laboris nisut aliquip ex ea commod Duis aute
                        irure dolorn reprehenderit voluptate velit esse
                      </p>
                    </div>
                
                  </div>
                </article>
              
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPost;
