const menu_data = [
  {
    id: 1,
    title: '家',
    link: '/'
 
  },
  {
    id: 2,
    title: '关于',
    link: '/about',
  },
  {
    id: 3,
    title: '服务',
    link: '/service'
   
  },
  {
    id: 4,
    title: '常问问题',
    link: '/faq',
   
  },
  {
    id: 5,
    title: '文件夹',
    link: '/portfolio',
   
  },
  {
    id: 6,
  
    title: '博客',
    link: '/blog',
   
  },
  {
    id: 7,
    title: '联系我们',
    link: '/contact',
   
  },
]

export default menu_data;
