import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BlogDetails2Banner from "./BlogDetails2Banner";
import BlogDetails2Post from "./BlogDetails2Post";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const EnhancingOperationalEfficiencySimpleStepsforBusinesses = () => {
  return (
    <>
    <Helmet>
        <title>{meta.blog1.title}</title>
        <meta name="description" content={meta.blog1.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <BlogDetails2Banner />
      <BlogDetails2Post />
      <Footer />
    </>
  );
};

export default EnhancingOperationalEfficiencySimpleStepsforBusinesses;
