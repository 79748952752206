import React from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Portfolio from '../components/Pages/Portfolio/Portfolio';
import PortfolioDetails from '../components/Pages/PortfolioDetails/PortfolioDetails';
import Service from '../components/Pages/Service/Service';
import ServiceDetails from '../components/Pages/ServiceDetails/ServiceDetails';
import BudgetaryPlanningandAnalysis from '../components/Pages/BudgetaryPlanningandAnalysis/BudgetaryPlanningandAnalysis';
import BusinessStrategyDevelopment from '../components/Pages/BusinessStrategyDevelopment/BusinessStrategyDevelopment';
import DigitalTransformationConsultancy from '../components/Pages/DigitalTransformationConsultancy/DigitalTransformationConsultancy';
import HumanResourcesConsulting from '../components/Pages/HumanResourcesConsulting/HumanResourcesConsulting';
import LeadershipandTeamDevelopment from '../components/Pages/LeadershipandTeamDevelopment/LeadershipandTeamDevelopment';
import MarketEntryandExpansionConsulting from '../components/Pages/MarketEntryandExpansionConsulting/MarketEntryandExpansionConsulting';
import OperationalEfficiencyConsulting from '../components/Pages/OperationalEfficiencyConsulting/OperationalEfficiencyConsulting';
import RiskManagementandCompliance from '../components/Pages/RiskManagementandCompliance/RiskManagementandCompliance';
import EnhancingOperationalEfficiencySimpleStepsforBusinesses from '../components/Pages/EnhancingOperationalEfficiencySimpleStepsforBusinesses/EnhancingOperationalEfficiencySimpleStepsforBusinesses';
import ExpandingintoNewMarketsWhatYouNeedtoKnow from '../components/Pages/ExpandingintoNewMarketsWhatYouNeedtoKnow/ExpandingintoNewMarketsWhatYouNeedtoKnow';
import HowtoDevelopaWinningBusinessStrategy from '../components/Pages/HowtoDevelopaWinningBusinessStrategy/HowtoDevelopaWinningBusinessStrategy';
import CookiesPolicy from '../components/Pages/CookiesPolicy/CookiesPolicy';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy/PrivacyPolicy';
import TermsandConditions from '../components/Pages/TermsandConditions/TermsandConditions';
import NotFoundView from '../components/Pages/NotFoundView/NotFoundView';
import LoginForm from '../components/Pages/Login/LoginForm';
import Admin from '../components/Pages/Admin/Admin';
import { AuthProvider } from '../AuthContext';
import ProtectedRoute from '../ProtectedRoute';


const AppNavigation = () => {
  return (
    <AuthProvider>
    <ContextProvider>
      <AnimateMouse/>
      <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/admin" element={<ProtectedRoute element={<Admin />} />} />
      
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/budgetary-planning-and-analysis" element={<BudgetaryPlanningandAnalysis />} />
        <Route path="/business-strategy-development" element={<BusinessStrategyDevelopment />} />
        <Route path="/digital-transformation-consultancy" element={<DigitalTransformationConsultancy />} />
        <Route path="/human-resources-consulting" element={<HumanResourcesConsulting />} />
        <Route path="/leadershipand-team-development" element={<LeadershipandTeamDevelopment />} />
        <Route path="/marketentry-and-expansion-consulting" element={<MarketEntryandExpansionConsulting />} />
        <Route path="/operational-efficiency-consulting" element={<OperationalEfficiencyConsulting />} />
        <Route path="/risk-managementand-compliance" element={<RiskManagementandCompliance />} />
        <Route path="/enhancing-operational-efficiency-simple-steps-for-businesses" element={<EnhancingOperationalEfficiencySimpleStepsforBusinesses />} />
        <Route path="/expanding-into-new-markets-what-you-need-to-know" element={<ExpandingintoNewMarketsWhatYouNeedtoKnow />} />
        <Route path="/how-to-developa-winning-business-strategy" element={<HowtoDevelopaWinningBusinessStrategy />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsandConditions />} />
        {/* <Route path="alskd'nxkl/" element={<Navigate to="/alskd'nxkl" replace />} /> */}
        <Route path="*" element={<NotFoundView />} />     
      </Routes>
      <BackToTop/>
    </ContextProvider>
    </AuthProvider>
  );
};

export default AppNavigation;