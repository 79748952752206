import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Services from "../../Home/Services";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";


const About = () => {
  return (
    <>
    <Helmet>
        <title>{meta.about.title}</title>
        <meta name="description" content={meta.about.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />
      <Services />
      <Footer />
    </>
  );
};

export default About;
