import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Accordion from "./Accordion";
import FaqBanner from "./FaqBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const FAQ = () => {
  return (
    <>
    <Helmet>
        <title>{meta.faq.title}</title>
        <meta name="description" content={meta.faq.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <FaqBanner />
      <Accordion />
      <Contact />
      <Footer />
    </>
  );
};

export default FAQ;
