import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const BusinessStrategyDevelopmentAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅商業策略發展服務旨在幫你制定一個符合你公司願景同目標嘅穩健計劃。我哋首先會對你而家嘅商業環境進行全面分析，包括市場條件、競爭對手環境同內部能力。我哋嘅團隊會同你密切合作，嚟確定重要嘅業務目標同挑戰。
                根據呢個分析，我哋會制定一個策略路線圖，概述清晰、可行嘅步驟去達成你嘅目標。呢個包括界定戰略優先事項、設定可量度嘅目標，同埋制定詳細嘅行動計劃。我哋確保策略唔單止符合你嘅長遠願景，亦都適應市場動態嘅變化。透過利用行業洞察力同最佳做法，我哋幫你解決複雜問題，同埋為你嘅業務定位可持續增長。
                </p>

                

                <h4 className="tp-sv__subtitle">
                啲客會得到啲咩
                </h4>
                <p>
                客戶可以獲得一個根據佢哋獨特嘅需要同市場條件而度身訂造嘅結構良好同可行嘅商業策略。我哋嘅服務提供清晰同方向，幫你設定現實嘅目標同埋達到可量度嘅成果。
                你會受益於一個突顯成長機會同埋主動應對潛在挑戰嘅戰略計劃。我哋嘅方法確保你有一個清晰嘅路線圖可以遵循，同埋實際嘅實施步驟。喺我哋嘅指導下，你可以提升你嘅競爭優勢，作出明智嘅決定，同埋為你嘅業務推動長遠嘅成功。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default BusinessStrategyDevelopmentAbout;
