import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BudgetaryPlanningandAnalysisAbout from "./BudgetaryPlanningandAnalysisAbout";
import BudgetaryPlanningandAnalysisBanner from "./BudgetaryPlanningandAnalysisBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";



const BudgetaryPlanningandAnalysis = () => {
  return (
    <>

      <Helmet>
        <title>{meta.budgetaryplanningandanalysis.title}</title>
        <meta name="description" content={meta.budgetaryplanningandanalysis.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <BudgetaryPlanningandAnalysisBanner />
      <BudgetaryPlanningandAnalysisAbout />
      <Contact />
      <Footer />
    </>
  );
};

export default BudgetaryPlanningandAnalysis;
