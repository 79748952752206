import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import TermsandConditionsBanner from "./TermsandConditionsBanner";
import TermsandConditionsPost from "./TermsandConditionsPost";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const TermsandConditions = () => {
  return (
    <>
    <Helmet>
        <title>{meta.termsconditions.title}</title>
        <meta name="description" content={meta.termsconditions.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <TermsandConditionsBanner />
      <TermsandConditionsPost />
      <Footer />
    </>
  );
};

export default TermsandConditions;
