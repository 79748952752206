import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import PortfolioDetailsBanner from "./PortfolioDetailsBanner";
import PortfolioDetailsSingle from "./PortfolioDetailsSingle";

const PortfolioDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <PortfolioDetailsBanner />
      <PortfolioDetailsSingle />
      <Footer />
    </>
  );
};

export default PortfolioDetails;
