import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bannerConfig from '../../../data/bannerConfig.json';
import { useAuth } from '../../../AuthContext';

const Admin = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('inactive');
  const [inputValue, setInputValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const urlLink = bannerConfig.url_link;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  // Load saved option and input value from localStorage on mount
  useEffect(() => {
    const storedOption = localStorage.getItem('selectedOption');
    const storedInputValue = localStorage.getItem('inputValue');

    if (storedOption) {
      setSelectedOption(storedOption);
      setInputValue(storedInputValue || '');
      setIsInputVisible(storedOption === 'active'); // Show input field if active is selected
    }
  }, []);

  const handleFirstCheckboxChange = () => {
    setSelectedOption('active');
    localStorage.setItem('selectedOption', 'active');
    setIsInputVisible(true);
    setInputValue(urlLink); // Optional: preset the input value
  };

  const handleSecondCheckboxChange = () => {
    setSelectedOption('inactive');
    localStorage.setItem('selectedOption', 'inactive');
    setIsInputVisible(false);
    setInputValue(''); // Clear input when switching to inactive
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSave = { inputValue, selectedOption };

    try {
      const response = await fetch("https://api.rootemi.hk/update-banner-config", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSave),
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      
      setShowPopup(true);
      localStorage.setItem('inputValue', inputValue); // Save the input value
    } catch (error) {
      alert('There was an error updating the configuration.');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <div className="col-lg-4" style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <h1 className="text-center mb-4">Admin Dashboard</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-check">
                <input type="radio" className="form-check-input" checked={selectedOption === 'active'} onChange={handleFirstCheckboxChange} />
                <label className="form-check-label">Active</label>
              </div>

              {isInputVisible && (
                <div className="form-group mt-3">
                  <label htmlFor="inputField">Input Field:</label>
                  <input 
                    id="inputField" 
                    type="text" 
                    value={inputValue} 
                    onChange={(e) => setInputValue(e.target.value)} 
                    className="form-control" 
                  />
                </div>
              )}

              <div className="form-check mt-3">
                <input type="radio" className="form-check-input" checked={selectedOption === 'inactive'} onChange={handleSecondCheckboxChange} />
                <label className="form-check-label">Inactive</label>
              </div>

              <button type="submit" className="btn btn-primary w-100 mt-4">Submit</button>
              <button 
                type="button" 
                onClick={() => navigate('/')} 
                className={`btn w-100 mt-4 ${selectedOption === 'active' ? 'btn-success' : 'btn-secondary'}`}
              >
                Go to Home Page
              </button>
              <button 
                type="button" 
                onClick={handleLogout} 
                className="btn btn-danger w-100 mt-4"
              >
                Logout
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Popup Modal */}
      {showPopup && (
        <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className="modal-content" style={{ margin: 'auto', padding: '20px', backgroundColor: 'white', borderRadius: '5px', maxWidth: '400px' }}>
            <h5>Success</h5>
            <p>Configuration updated successfully!</p>
            <button onClick={closePopup} className="btn btn-primary">Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
