import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                  關於Rootemi
                  </span>
                  <h2 className="tp-pt-size mb-40">
                  透過有見地同實用嘅顧問解決方案，令企業更有能力
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        我哋嘅使命
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                       我哋嘅願景
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                       我哋嘅價值
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                      喺Rootemi，我哋嘅使命係為企業提供策略同見解，從而促進有意義嘅增長。我哋專注於了解每個客戶嘅獨特挑戰，確保我哋提供嘅指引唔單止可行，亦都符合佢哋嘅目標。透過提供實用嘅解決方案同個人化支援，我哋旨在幫助企業應對佢哋行業嘅複雜性。我哋相信要令我哋嘅客戶能夠取得可量度嘅成功，呢個係由對持續改善嘅深刻承諾同埋促進真正進步嘅長期夥伴關係所驅動。
                      </p>

                      
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                      我哋設想一個未來，企業可以輕鬆噉使用喺不斷發展嘅市場中蓬勃發展所需嘅工具同指引。喺 Rootemi，我哋嘅願景係成為希望完善策略、優化營運同擁抱新機會嘅公司嘅可信任合作夥伴。我哋努力培育一個創新同適應能力係成功嘅關鍵動力嘅商業環境。透過緊貼行業趨勢同挑戰，我哋尋求為客戶提供可持續進步所需嘅見解。
                      </p>

                      
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                      Rootemi嘅核心係致力於透過透明度、協作同實際解決方案提供價值。我哋相信同我哋嘅客戶密切合作，了解佢哋嘅挑戰，並提供根據佢哋嘅需要量身訂造嘅策略。誠信係我哋嘅方法嘅基礎，確保每個建議都係基於深入嘅分析同現實嘅成果。我哋對卓越嘅承諾意味著我哋一貫努力超越預期，喺我哋形成嘅每個合作夥伴關係中，培養建立喺信任、可靠性同共同成功嘅長期關係。
                      </p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="/assets/img/about/about-page.png" alt="" />
                </div>
                <div className="dots-img">
                  <img src="/assets/img/about/dot.jpg" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">專家</h3>
                  <h4 className="box-subtitle">
                  商業成功嘅解決方案 
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
