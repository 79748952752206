"use client";

import React, { useEffect, useState } from "react";

const TopBanner = () => {
  const [windowWidth, setWindowWidth] = useState(0); // Start with 0 as a placeholder
  const [bannerSettings, setBannerSettings] = useState({
    is_active: false,
    link: "",
  });

  useEffect(() => {
    // Ensure code only runs on the client
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);

      // Handle window resize
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      // Clean up event listener on unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    // Fetch current banner settings
    fetch("/get-image-url-rootemi")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        const isActive = data.is_active === "true";
        setBannerSettings({
          is_active: isActive,
          link: data.link,
        });
      })
      .catch((error) => {
        console.error("Error fetching banner settings:", error);
      });
  }, []);

  const { is_active, link } = bannerSettings;

  const handleRedirect = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  if (!is_active) {
    return null;
  }

  return (
    <div>
      <style>
        {`
          @media only screen and (max-width: 767px) {
            #bgBanner h2 {
              font-size: 30px !important;
              line-height: 40px !important;
            }
            #bgBanner a {
              padding: 15px 20px !important;
              font-size: 20px !important;
            }
          }
        `}
      </style>

      <div
        id="bgBanner"
        onClick={handleRedirect}
        style={{
          background:
            "linear-gradient(0deg, rgba(2, 0, 1, 0.9), rgba(2, 0, 1, 0.9))",
          width: "100%",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          cursor: "pointer",
          display: is_active ? "block" : "none",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ width: "100%" }} className="text-center">
            <h2
              style={{
                fontWeight: 600,
                lineHeight: "60px",
                color: "#fff",
                fontSize: "50px",
                textTransform: "uppercase",
                marginBottom: "50px",
                fontFamily: "sans-serif !important",
              }}
            >
              Please Click Here to Continue
            </h2>
            <div>
              <button
                className="vs-btn style13"
                onClick={handleRedirect}
                style={{
                  background: "#048d1b",
                  color: "rgb(255, 255, 255)",
                  padding: "20px 10%",
                  textDecoration: "none !important",
                  fontFamily: "sans-serif !important",
                  fontWeight: 600,
                  fontSize: "30px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  transition: "transform 0.3s, background-color 0.3s",
                  transform: "scale(1)",
                }}
              >
                CLICK TO CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
