import React from "react";
import { Link } from "react-router-dom";
// import BlogSidebar from "../Blog/BlogSidebar";

const TermsandConditionsPost = () => {
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-20">
                <article className="postbox__item format-image mb-50 transition-3">
                  
                  <div className="postbox__content">


                    <div className="postbox__text">
                     
                
                      <p>歡迎嚟到 rootemi。使用我哋嘅網站同埋參與我哋嘅顧問服務，即係你同意呢啲條款同細則。請花啲時間睇吓以下內容，因為佢哋概述咗使用我哋網站同服務嘅指引。</p>
                    
                    


                      <h3 className="postbox__subtite mb-25">
                      接受條款
                      </h3>
                      <p> 當你存取或者使用我哋嘅網站，即係你同意呢啲條款。如果你唔同意呢啲條款嘅任何部分，我哋請你唔好用我哋嘅網站或者服務。你繼續使用呢個網站，即係你接受呢啲條款所作出嘅任何修改。</p>
                   
                    


                      <h3 className="postbox__subtite mb-25">
                      提供嘅服務
                     </h3>
                     <p>rootemi為企業提供顧問服務，根據你嘅特定需要提供策略建議。請注意，雖然我哋努力提供準確同最新嘅資訊，但我哋嘅建議同內容只係為咗一般指引。我哋唔保證特定嘅結果，而你根據我哋嘅建議作出嘅任何決定都係由你酌情決定。 </p>
                 


                     <h3 className="postbox__subtite mb-25">
                     付款
                     </h3>
                     <p> 我哋只接受我哋提供嘅顧問服務嘅現金付款。完成約定嘅服務之後就要付款。一旦付款，除非另有書面同意，否則唔會退款。我哋感謝你對呢個政策嘅理解，同埋會確保任何帳單或者付款相關嘅查詢都會得到及時處理。</p>
                    


                     <h3 className="postbox__subtite mb-25">
                     知識產權
                     </h3>
                     <p>你喺我哋網站上面搵到嘅內容，包括但唔限於文字、圖像同標誌，都係由 rootemi擁有。呢啲材料受知識產權法保護，如果你冇事先得到書面許可，你唔可以使用、複製或者分發我哋網站嘅任何內容。 </p>
                     <p> 歡迎你瀏覽同埋用我哋嘅網站嚟做個人或者非商業用途。不過，你唔可以喺未經我哋明確同意嘅情況下以任何形式複製、修改或者重新發佈我哋嘅內容。</p>
                    


                     <h3 className="postbox__subtite mb-25">
                     網站使用情況
                     </h3>
                     <p>使用我哋嘅網站，即係你同意唔參與任何可能會打擾或者干擾佢嘅正常運作嘅活動。呢個包括但唔限於引入病毒、過載伺服器，或者參與任何旨在損害網站安全嘅惡意活動。 </p>
                     <p> 你亦同意唔會將我哋嘅網站用喺任何非法目的或者侵犯其他人權利嘅方式。如果我哋懷疑你有任何濫用或者違反呢啲條款嘅行為，我哋保留終止你存取我哋網站嘅權利。</p>
                  


                     <h3 className="postbox__subtite mb-25">
                     責任嘅限制
                     </h3>
                     <p> rootemi對於你使用我哋嘅服務或者網站可能引起嘅任何損害、損失或者索償，都唔負責。雖然我哋努力保持網站準確同運作，但我哋唔保證網站唔會有錯誤或者中斷。</p>
                     <p> 根據我哋提供嘅建議或資料作出嘅任何決定都係你嘅責任。對於你用我哋嘅顧問服務作出嘅商業或個人決定所造成嘅任何後果，我哋都唔會負責。</p>
                


                     <h3 className="postbox__subtite mb-25">
                     第三方連結
                     </h3>
                     <p> 我哋嘅網站有時可能會包含外部網站或資源嘅連結。呢啲連結只係為咗方便而提供，我哋唔認可或者控制第三方網站上面嘅內容。 rootemi對任何外部內容唔負責，並鼓勵你獨立睇返呢啲網站嘅條款同細則。</p>
                     

                     <h3 className="postbox__subtite mb-25">
                     隱私
                     </h3>
                     <p> 你嘅私隱對我哋嚟講好重要，我哋會負責任噉處理你嘅數據。請參閱我哋嘅私隱政策，瞭解我哋點樣遵守新加坡嘅個人資料保護法（ PDPA ）收集、使用同保護你嘅個人資料嘅更多詳細資料。</p>
                  



                     <h3 className="postbox__subtite mb-25">
                     條款嘅修訂
                     </h3>
                     <p>我哋可能會不時更新呢啲條款，以反映我哋服務、法律要求或者其他因素嘅變動。當作出變更嗰陣，佢哋會喺呢個頁面發佈。我哋鼓勵你定期睇返呢啲條款，以便緊貼任何更新。 </p>
                     <p> 你喺發佈任何變更之後繼續使用網站，即係你接受修訂後嘅條款。如果你對呢啲條款嘅變動有任何疑慮，隨時直接同我哋聯絡。</p>
                     



                     <h3 className="postbox__subtite mb-25">
                     管治法
                     </h3>
                     <p> 呢啲條款受新加坡法律管轄同埋根據新加坡法律解釋。由於呢啲條款或者同呢啲條款有關嘅任何爭議或者索償，都應該受新加坡法院嘅專屬管轄權。</p>
                  

                     <h3 className="postbox__subtite mb-25">
                     聯絡我哋
                     </h3>
                     <p>如果你對呢啲條款同細則有任何疑問，或者你需要進一步澄清，請透過  consult@rootemi.hk 同我哋聯絡。我哋喺度協助你，確保你喺 rootemi嘅體驗盡量順利。 </p>
                   

                     
                     


                     
                      
                
                    </div>

                  </div>
                </article>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandConditionsPost;
