import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import HumanResourcesConsultingAbout from "./HumanResourcesConsultingAbout";
import HumanResourcesConsultingBanner from "./HumanResourcesConsultingBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";



const HumanResourcesConsulting = () => {
  return (
    <>

      <Helmet>
        <title>{meta.humanresourcesconsultancy.title}</title>
        <meta name="description" content={meta.humanresourcesconsultancy.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <HumanResourcesConsultingBanner />
      <HumanResourcesConsultingAbout />

      <Contact />
      <Footer />
    </>
  );
};

export default HumanResourcesConsulting;
