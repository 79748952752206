import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BlogBanner from "./BlogBanner";
import BlogPost from "./BlogPost";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const Blog = () => {
  return (
    <>
    <Helmet>
        <title>{meta.blogs.title}</title>
        <meta name="description" content={meta.blogs.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <BlogBanner />
      <BlogPost />
      <Footer/>
    </>
  );
};

export default Blog;
