import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import OperationalEfficiencyConsultingAbout from "./OperationalEfficiencyConsultingAbout";
import OperationalEfficiencyConsultingBanner from "./OperationalEfficiencyConsultingBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";


const OperationalEfficiencyConsulting = () => {
  return (
    <>

<Helmet>
        <title>{meta.operationalefficiencyconsulting.title}</title>
        <meta name="description" content={meta.operationalefficiencyconsulting.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <OperationalEfficiencyConsultingBanner />
      <OperationalEfficiencyConsultingAbout />
      <Contact />
      <Footer />
    </>
  );
};

export default OperationalEfficiencyConsulting;
