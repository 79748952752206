import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const OperationalEfficiencyConsultingAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                
                我哋嘅營運效率顧問服務旨在簡化你嘅業務營運同提高整體生產力。我哋首先會對你而家嘅流程、系統同工作流程進行詳細評估。呢個涉及分析你嘅營運嘅各個方面，包括資源分配、流程成效同埋績效指標。
我哋嘅團隊會識別出效率低下、瓶頸同埋需要改善嘅地方。然後我哋會提供針對性嘅建議，嚟優化你嘅營運，當中可能包括流程重新設計、技術整合同埋資源管理策略。透過實施呢啲改進，我哋可以幫你降低成本、提升營運績效，同埋喺你嘅日常活動中提高效率。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                客戶將會受益於更精簡同有效嘅營運框架。我哋嘅服務可以幫你消除浪費、降低營運成本同埋提高生產力。你會深入瞭解效率低下嘅領域，同埋收到可行嘅建議，嚟提升績效。
                透過優化你嘅營運，你可以達到更好嘅資源利用率同埋更快嘅周轉時間。我哋嘅方法確保你嘅業務運作更加敏捷同埋適應市場需求，最終支援你嘅整體戰略目標同埋提升你嘅競爭地位。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default OperationalEfficiencyConsultingAbout;
