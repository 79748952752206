import React from "react";
// import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import NotFoundViewAbout from "./NotFoundViewAbout";
import NotFoundViewBanner from "./NotFoundViewBanner";
import NotFound from "../../Home/NotFound";

const NotFoundView = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <NotFoundViewBanner />
      <NotFoundViewAbout />
      <NotFound/>
      <Footer />
    </>
  );
};

export default NotFoundView;
