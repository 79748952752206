import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const MarketEntryandExpansionConsultingAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅市場進入同擴張顧問服務為想進入新市場或者擴大現有營運嘅企業提供專家指導。我哋會先進行徹底嘅市場分析，嚟了解當地嘅情況，包括消費者行為、監管要求同競爭環境。
                我哋嘅團隊會同你合作，制定一個針對呢啲因素嘅市場進入策略或者擴張計劃。我哋會喺識別潛在合作夥伴、克服監管障礙，同埋喺新市場有效定位你嘅品牌方面提供支援。我哋會透過提供策略見解同可行嘅建議，幫你減輕風險同埋利用增長機會。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                我哋嘅市場進入同擴張顧問服務為想進入新市場或者擴大現有營運嘅企業提供專家指導。我哋會先進行徹底嘅市場分析，嚟了解當地嘅情況，包括消費者行為、監管要求同競爭環境。
我哋嘅團隊會同你合作，制定一個針對呢啲因素嘅市場進入策略或者擴張計劃。我哋會喺識別潛在合作夥伴、克服監管障礙，同埋喺新市場有效定位你嘅品牌方面提供支援。我哋會透過提供策略見解同可行嘅建議，幫你減輕風險同埋利用增長機會。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default MarketEntryandExpansionConsultingAbout;
