import React from "react";
import { Link } from "react-router-dom";
// import BlogSidebar from "../Blog/BlogSidebar";

const PrivacyPolicyPost = () => {
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-20">
                <article className="postbox__item format-image mb-50 transition-3">
                  
                  <div className="postbox__content">


                    <div className="postbox__text">
                     
                
                      <p>喺 rootemi，我哋重視你嘅私隱，同埋明白保護你嘅個人資料嘅重要性。呢個政策概述咗我哋喺你同我哋嘅網站同服務互動嗰陣點樣收集、使用同管理你嘅資料。呢個反映咗我哋承諾負責任噉處理你嘅資料，同埋遵守新加坡嘅個人資料保護法（ PDPA ）。</p>
                    
                    


                      <h3 className="postbox__subtite mb-25">
                      我哋收集嘅資料
                      </h3>
                      <p> 當你瀏覽我哋嘅網站或者參與我哋嘅商業服務嗰陣，我哋可能會向你收集某啲資料。呢個可能包括：</p>
                      <p>個人數據：例如你嘅姓名、電郵地址同聯絡資料。呢啲資料通常會喺你喺我哋網站填寫表格、查詢我哋嘅服務或者直接聯絡我哋嗰陣收集。 </p>
                      <p>技術數據：我哋亦都會收集同你瀏覽我哋網站有關嘅數據。包括你嘅 IP 位址、瀏覽器類型同埋你點樣瀏覽我哋嘅網站等等嘅詳情。呢啲資料有助我哋改善我哋網站嘅功能同內容。 </p>
                     

                      <h3 className="postbox__subtite mb-25">
                      我哋點樣用你嘅資料
                    </h3>
                    <p>我哋收集嘅資料只係用嚟為你提供更好嘅服務。特別係，我哋會用你嘅個人資料嚟： </p>
                    <p>  回應你嘅查詢：如果你有問題或要求，我哋會用你提供嘅詳情嚟及時同準確噉回應。</p>
                    <p> 改善我哋嘅服務：透過分析我哋收集嘅技術數據，我哋努力提升我哋網站嘅效能同用戶體驗。呢個亦都令我哋可以根據訪客嘅行為去改良我哋嘅服務。</p>
                    <p> 保持溝通：我哋可能會向你發送有關你表示有興趣嘅服務嘅最新消息或者資訊。不過，我哋尊重你嘅偏好，你可以隨時選擇唔接收任何通訊。</p>


                    <h3 className="postbox__subtite mb-25">
                    分享你嘅資料
                    </h3>
                    <p> 喺 rootemi，我哋尊重你嘅私隱，唔會同第三方出售或者交易你嘅個人資料。不過，我哋可以同協助我哋營運網站或者進行業務嘅信任服務供應商分享你嘅資料，只要佢哋同意保密呢啲資料。</p>
                    <p>喺某啲情況下，法律可能要求我哋披露你嘅個人資料。我哋只會喺有必要遵守法律義務或者保護我哋嘅權利、財產或者安全嘅情況下先會咁做。 </p>
                   


                    <h3 className="postbox__subtite mb-25">
                    保護緊你嘅數據
                    </h3>
                    <p> 我哋認真對待資料安全，並採取適當措施保護你嘅個人資料。只限授權人員存取你嘅資料，我哋會用安全方法保護資料免受未經授權嘅存取、披露或者修改。</p>
                    <p> 雖然我哋會努力保護你嘅數據，但係請注意，冇一種電子傳輸或者儲存方法係完全安全嘅。不過，我哋會盡一切努力使用商業上可以接受嘅方法嚟確保你嘅數據受到保護。</p>
                   


                    <h3 className="postbox__subtite mb-25">
                    你嘅權利
                    </h3>
                    <p>根據新加坡嘅 PDPA ，你有權存取、更新或者要求刪除你嘅個人資料。如果你認為我哋保留嘅任何資料都唔準確，或者你想行使你嘅權利，你可以透過  consult@rootemi.hk 同我哋聯絡。我哋會喺合理嘅時間內回應你嘅要求。 </p>
                    <p>你亦可以選擇退出某啲通訊，或者限制我哋透過你嘅瀏覽器設定收集嘅資料類型，嚟管理你嘅通訊偏好設定。 </p>
                


                    <h3 className="postbox__subtite mb-25">
                    資訊保留
                    </h3>
                    <p> 我哋只會保留你嘅個人資料，只要係為咗達到呢個政策所概述嘅目的而必要嘅時間。一旦唔再需要呢啲資料，就會根據我哋嘅資料保留指引安全咁處理。</p>
                   

                    <h3 className="postbox__subtite mb-25">
                    呢個私隱權政策嘅更新
                    </h3>
                    <p>我哋可能會偶爾更新呢個私隱政策，以反映我哋嘅數據做法或者法律義務嘅任何變動。當更新完成之後，修訂版本會喺呢個頁面發佈，我哋鼓勵你定期睇返佢。 </p>
                    


                    <h3 className="postbox__subtite mb-25">
                    聯絡我哋
                    </h3>
                    <p>如果你對呢個私隱權政策或者我哋點樣處理你嘅資料有任何疑問、疑慮或者要求，請隨時透過  consult@rootemi.hk 同我哋聯絡。我哋致力確保你嘅私隱得到尊重，同埋你喺 rootemi嘅體驗係正面嘅。 </p>
                  
                     


                     
                      
                
                    </div>

                  </div>
                </article>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPost;
