import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const DigitalTransformationConsultancyAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅數碼轉型顧問服務幫助企業整合現代科技，以提高效率同推動創新。我哋會評估你而家嘅科技環境，同埋找出數碼改進嘅機會。
                我哋嘅團隊會同你合作，制定一個符合你嘅業務目標嘅數碼策略，同埋解決轉型嘅關鍵領域。包括採用新技術、優化數碼流程同埋改善顧客互動。我哋會提供有關實施數碼解決方案嘅指引，呢啲解決方案可以提高營運效率同支援長遠增長。我哋嘅目標係幫你利用科技嚟獲得競爭優勢同埋實現商業成功。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                客戶可以透過數碼轉型，從更有效率同創新嘅商業環境中獲益。我哋嘅服務提供咗一個清晰嘅策略，幫你採用同整合技術，嚟提升你嘅營運同客戶體驗。
                你會對數碼機會有深入嘅了解，同埋會收到實際嘅實施建議。透過擁抱數碼解決方案，你可以提高效率、簡化流程同埋推動創新。我哋嘅專業知識確保你嘅業務有良好嘅地位，可以利用科技嚟增長，同埋喺快速發展嘅市場中保持競爭力。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default DigitalTransformationConsultancyAbout;
