import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import RiskManagementandComplianceAbout from "./RiskManagementandComplianceAbout";
import RiskManagementandComplianceBanner from "./RiskManagementandComplianceBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const RiskManagementandCompliance = () => {
  return (
    <>
    <Helmet>
        <title>{meta.riskManagementandcompliance.title}</title>
        <meta name="description" content={meta.riskManagementandcompliance.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <RiskManagementandComplianceBanner />
      <RiskManagementandComplianceAbout />
      <Contact />
      <Footer />
    </>
  );
};

export default RiskManagementandCompliance;
