import React from "react";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BusinessStrategyDevelopmentAbout from "./BusinessStrategyDevelopmentAbout";
import BusinessStrategyDevelopmentBanner from "./BusinessStrategyDevelopmentBanner";
import { Helmet } from "react-helmet";
import meta from "../../../data/meta.json";

const BusinessStrategyDevelopment = () => {
  return (
    <>

<Helmet>
        <title>{meta.businessstrategydevelopment.title}</title>
        <meta name="description" content={meta.businessstrategydevelopment.desc} />
      </Helmet>
      <Menu header_style_2={true} />
      <BusinessStrategyDevelopmentBanner />
      <BusinessStrategyDevelopmentAbout />
      <Contact />
      <Footer />
    </>
  );
};

export default BusinessStrategyDevelopment;
