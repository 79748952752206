import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const HumanResourcesConsultingAbout = () => {
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">服務描述</h3>
                <p>
                我哋嘅人力資源顧問服務主要係優化你嘅人力資源做法同埋提升員工參與度。我哋首先評估你現有嘅人力資源流程，包括招聘、績效管理同埋員工發展。
                我哋嘅團隊提供策略建議嚟改善人力資源職能，例如制定有效嘅招聘策略、磨練績效評估方法，同埋創造專業發展計劃。我哋協助設計符合你嘅業務目標同埋培養正面嘅工作環境嘅人力資源政策。透過實施最佳做法，我哋幫你建立一個更有效嘅人力資源功能，支援你嘅組織目標。
                </p>

                

                <h4 className="tp-sv__subtitle">
                客戶會獲得啲咩
                </h4>
                <p>
                客戶可以透過我哋嘅顧問服務獲得改善嘅人力資源流程同埋提升員工參與度。我哋嘅建議有助簡化招聘、績效管理同發展工作，令人力資源功能更有效。
                你會受益於一個更加投入同有動力嘅勞動力，從而提高生產力同工作滿意度。透過將人力資源做法同業務目標一致，你可以創造一個支持組織成長嘅正面工作環境。我哋嘅專業知識確保你嘅人力資源功能有助於整體業務成功，同埋幫你吸引同留住頂尖人才。
                </p>
               

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default HumanResourcesConsultingAbout;
