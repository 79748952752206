import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

const ContactHome = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send("service_bs9k8k6", "template_n2andll", formData, "OuRieeM4Uc3ZVmNTL")
      .then((result) => {
        setResponseMessage("Thank you for your message!");
        setFormData({ name: "", email: "", message: "" });
      }, (error) => {
        setResponseMessage("There was an error sending your message.");
      });
  };

  return (
    <>
      {/* <!-- contact area start --> */}
      <div
        className="tp-contact-area"
        style={{ backgroundImage: `url("/assets/img/contact/contact us.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-contact-info pt-180 pb-150 pr-50">
                <div className="tp-section-wrapper p-color-change">
                  <span className="tp-section-subtitle text-white mb-30">
                    今日開始你嘅旅程
                  </span>
                  <h2 className="tp-section-title text-white mb-35">
                    尋求量身定制嘅商業顧問解決方案
                  </h2>
                  <p>
                    我哋喺度為你嘅獨特需要提供個人化建議同專家指導，幫你嘅業務發展。
                  </p>
                  <div className="mt-50">
                    <Link to="/service" className="tp-btn-white">
                      查看更多
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-contact-form white-bg pt-60 pl-50 pr-50 pb-60">
                <h4 className="tp-contact-form-title">
                  <i className="fal fa-file-edit"></i>聯絡我哋 
                </h4>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="輸入你個名"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="輸入你嘅郵件"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <br />
                  <textarea
                    name="message"
                    placeholder="輸入你嘅訊息"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                  <button type="submit" className="tp-btn-border">
                    送按摩
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </form>
                {responseMessage && <p className="ajax-response mt-20 text-center">{responseMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- contact area end  --> */}
    </>
  );
};

export default ContactHome;
